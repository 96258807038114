import dynamic from "next/dynamic";
import { toFieldPath } from "@stackbit/annotations";
import React from "react";
import { Container } from "../components/Container";
import { Section } from "../components/Section";
import Hero from "./Hero";
import { HeroSectionProps } from "./types";
import clsx from "clsx";
const HomepageImage = dynamic(() => import("./HomepageImage").then(e => e.HomepageImage), {
  ssr: true
});
const SectionHero: React.FC<HeroSectionProps> = ({
  annotationPrefix,
  bgClassName,
  sectionVariant = "normal",
  section_id,
  homepageHero = false,
  ...heroProps
}) => {
  return <Section id={section_id} variant={sectionVariant} bgClassName={bgClassName} {...toFieldPath(annotationPrefix)} data-sentry-element="Section" data-sentry-component="SectionHero" data-sentry-source-file="SectionHero.tsx">
      <Container data-sentry-element="Container" data-sentry-source-file="SectionHero.tsx">
        <Hero {...heroProps} className={clsx({
        "lg:h-[720px]": homepageHero
      })} alignment={homepageHero ? "left" : heroProps.alignment} image={homepageHero ? undefined : heroProps.image} data-sentry-element="Hero" data-sentry-source-file="SectionHero.tsx" />
        {homepageHero && <HomepageImage />}
      </Container>
    </Section>;
};
export default SectionHero;